import { useState, useRef } from 'react';
import Filter from '../../assets/icons/Filter';
import Popover from '@mui/material/Popover';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Divider from '@mui/material/Divider/Divider';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import { ToggleButton } from '../../components/ToggleButton';
import Checkbox from '../../components/Checkbox';
import { colors } from '../../theme';
import {
  DUE_DATE_FILTERS,
  FilterState,
  GESTATIONAL_AGE_FILTERS,
} from './hooks/usePatientFilters';
import { FilterChips } from './FilterChips';

type PatientFiltersProps = {
  filterState: FilterState;
  updateFilter: <K extends keyof FilterState>(
    category: K,
    value: FilterState[K] extends (infer U)[] ? U : FilterState[K],
    isChecked: boolean
  ) => void;
  clearFilters: () => void;
};
export default function PatientFilters({
  filterState,
  updateFilter,
  clearFilters,
}: PatientFiltersProps) {
  const filterAnchorElRef = useRef<HTMLButtonElement | null>(null);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const hideFilterMenu = () => setShowFilterMenu(false);
  const toggleFilterMenu = () => setShowFilterMenu(!showFilterMenu);
  const removeFilter = <K extends keyof FilterState>(
    category: K,
    value: FilterState[K] extends (infer U)[] ? U : FilterState[K]
  ) => {
    updateFilter(category, value, false);
  };

  return (
    <div className="flex flex-1 items-center">
      <ToggleButton
        value="check"
        selected={showFilterMenu}
        ref={filterAnchorElRef}
        onChange={toggleFilterMenu}
      >
        <Filter />
        Add filter
      </ToggleButton>
      <FilterChips
        filterState={filterState}
        removeFilter={removeFilter}
        clearFilters={clearFilters}
      />
      <Popover
        id="patient-filters"
        anchorEl={filterAnchorElRef.current}
        open={showFilterMenu}
        onClose={hideFilterMenu}
        elevation={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ paper: 'rounded-lg p-4' }}
      >
        <FormControl
          sx={{ p: 0, mb: 2 }}
          component="fieldset"
          variant="standard"
        >
          <FormLabel
            component="legend"
            classes={{
              root: 'text-bodyMedium text-primary font-semibold !mb-2',
            }}
          >
            Due Date
          </FormLabel>
          <RadioGroup
            value={filterState.dueDate || ''}
            className="gap-1"
            onChange={(e) =>
              updateFilter(
                'dueDate',
                e.target.value as keyof typeof DUE_DATE_FILTERS,
                true
              )
            }
          >
            {Object.entries(DUE_DATE_FILTERS).map(([key, value]) => (
              <FormControlLabel
                key={key}
                value={key}
                control={
                  <Radio
                    sx={{
                      color: colors.gray,
                      '&.Mui-checked': {
                        color: colors.green,
                      },
                    }}
                  />
                }
                label={value}
                classes={{
                  label: 'text-bodyMedium text-gray-500',
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Divider className="px-4 mx-auto" />
        <FormControl
          sx={{ p: 0, my: 2 }}
          component="fieldset"
          variant="standard"
        >
          <FormLabel
            component="legend"
            classes={{
              root: 'text-bodyMedium text-primary font-semibold !mb-2',
            }}
          >
            Gestational Age
          </FormLabel>
          <FormGroup>
            {Object.entries(GESTATIONAL_AGE_FILTERS).map(([key, value]) => (
              <Checkbox
                key={key}
                label={value}
                checked={filterState.gestationalAge.includes(
                  key as keyof typeof GESTATIONAL_AGE_FILTERS
                )}
                sx={{
                  color: colors.gray,
                  paddingY: '4px',
                  '&.Mui-checked': {
                    color: colors.green,
                  },
                }}
                onChange={(e) =>
                  updateFilter(
                    'gestationalAge',
                    key as keyof typeof GESTATIONAL_AGE_FILTERS,
                    e.target.checked
                  )
                }
              />
            ))}
          </FormGroup>
        </FormControl>
        <Divider />
        <FormControl
          sx={{ p: 0, mt: 2 }}
          component="fieldset"
          variant="standard"
        >
          <FormLabel
            component="legend"
            classes={{
              root: 'text-bodyMedium text-primary font-semibold !mb-2',
            }}
          >
            Patient Status
          </FormLabel>
          <FormGroup>
            <Checkbox
              sx={{
                color: colors.gray,
                paddingY: '4px',
                '&.Mui-checked': {
                  color: colors.green,
                },
              }}
              checked={filterState.patientStatus.includes('active')}
              label="Active"
              onChange={(e) =>
                updateFilter('patientStatus', 'active', e.target.checked)
              }
            />
            <Checkbox
              sx={{
                color: colors.gray,
                paddingY: '4px',
                '&.Mui-checked': {
                  color: colors.green,
                },
              }}
              checked={filterState.patientStatus.includes('archived')}
              onChange={(e) =>
                updateFilter('patientStatus', 'archived', e.target.checked)
              }
              label="Archived"
            />
            <Checkbox
              sx={{
                color: colors.gray,
                paddingY: '4px',
                '&.Mui-checked': {
                  color: colors.green,
                },
              }}
              checked={filterState.patientStatus.includes('prospective')}
              onChange={(e) =>
                updateFilter('patientStatus', 'prospective', e.target.checked)
              }
              label="Prospective"
            />
          </FormGroup>
        </FormControl>
        <Divider />
        <FormControl
          sx={{ p: 0, mt: 2 }}
          component="fieldset"
          variant="standard"
        >
          <FormLabel
            component="legend"
            classes={{
              root: 'text-bodyMedium text-primary font-semibold !mb-2',
            }}
          >
            Patient Type
          </FormLabel>
          <FormGroup>
            <Checkbox
              sx={{
                color: colors.gray,
                paddingY: '4px',
                '&.Mui-checked': {
                  color: colors.green,
                },
              }}
              checked={filterState.patientType.includes('prenatal')}
              label="Prenatal"
              onChange={(e) =>
                updateFilter('patientType', 'prenatal', e.target.checked)
              }
            />
            <Checkbox
              sx={{
                color: colors.gray,
                paddingY: '4px',
                '&.Mui-checked': {
                  color: colors.green,
                },
              }}
              checked={filterState.patientType.includes('postpartum')}
              onChange={(e) =>
                updateFilter('patientType', 'postpartum', e.target.checked)
              }
              label="Postpartum"
            />
          </FormGroup>
        </FormControl>
      </Popover>
    </div>
  );
}
