import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import {
  DueDateFilter,
  GestationalAgeFilter,
  GestationalAgeFilterKeys,
  PatientTypeFilter,
  StatusFilter,
} from '@aster/shared/dtos/patient';
import { useUserUsagePreferences } from '../../../hooks/useUserUsagePreference';

export const DUE_DATE_FILTERS = {
  'next-week': 'Next 7 days',
  'next-month': 'Next 30 days',
  'next-three-months': 'Next 90 days',
} as const;

export const GESTATIONAL_AGE_FILTERS = {
  'zero-to-twelve': '0 to 12 weeks',
  'thirteen-to-twentyseven': '13 to 27 weeks',
  'twentyeight-to-forty': '28 to 40 weeks',
  'more-than-forty': 'More than 40 weeks',
} as const;

export interface FilterState {
  dueDate: DueDateFilter;
  gestationalAge: GestationalAgeFilterKeys[];
  patientStatus: StatusFilter;
  patientType: PatientTypeFilter;
}

export interface ServerFilters {
  dueDateRange?: { start: string; end: string } | undefined;
  gestationalAgeFilter?: GestationalAgeFilter | undefined;
  statusFilter?: StatusFilter;
  patientTypeFilter?: PatientTypeFilter;
}

export const defaultFilterState: FilterState = {
  dueDate: null,
  gestationalAge: [],
  patientStatus: [],
  patientType: [],
};

const PATIENT_FILTER_PREFERENCE_KEY = '__patient-search-filters__';

export function usePatientFilters(
  initialFilterState = defaultFilterState,
  useStoredPreferences = false
) {
  const { storePreference, readPreference } = useUserUsagePreferences();
  const [filterState, setFilterState] = useState<FilterState>(
    useStoredPreferences
      ? readPreference<FilterState>(PATIENT_FILTER_PREFERENCE_KEY) ??
          initialFilterState
      : initialFilterState
  );

  /**
   * Updates the filter state based on the provided category and value.
   *
   * @template K - The key of the filter state.
   * @param {K} category - The category of the filter to update.
   * @param {FilterState[K] extends (infer U)[] ? U : FilterState[K]} value - The value to update the filter with.
   * @param {boolean} isChecked - Boolean indicating whether the filter is being added or removed.
   */
  const updateFilter = <K extends keyof FilterState>(
    category: K,
    value: FilterState[K] extends (infer U)[] ? U : FilterState[K],
    isChecked: boolean
  ) => {
    setFilterState((prevState) => {
      const newState: FilterState = { ...prevState };

      if (category === 'dueDate') {
        newState[category as 'dueDate'] = isChecked
          ? (value as FilterState['dueDate'])
          : null;
      } else {
        const prevCategory = prevState[category] as FilterState[
          | 'patientStatus'
          | 'patientType'
          | 'gestationalAge'];

        if (isChecked) {
          newState[category] = [...prevCategory, value] as FilterState[K];
        } else {
          newState[category] = prevCategory.filter(
            (item) => item !== value
          ) as FilterState[K];
        }
      }

      if (useStoredPreferences) {
        storePreference(PATIENT_FILTER_PREFERENCE_KEY, newState);
      }

      return newState;
    });
  };

  const clearFilters = () => {
    setFilterState(defaultFilterState);
    if (useStoredPreferences) {
      storePreference(PATIENT_FILTER_PREFERENCE_KEY, defaultFilterState);
    }
  };

  const serverFilters = useMemo(
    () => transformFilters(filterState),
    [filterState]
  );

  return { filterState, updateFilter, serverFilters, clearFilters };
}

function transformFilters(filters: FilterState): ServerFilters {
  return {
    dueDateRange: transformDueDate(filters.dueDate),
    gestationalAgeFilter: transformGestationalAge(filters.gestationalAge),
    statusFilter: filters.patientStatus,
    patientTypeFilter: filters.patientType,
  };
}

function transformDueDate(
  dueDate: string | null
): { start: string; end: string } | undefined {
  if (!dueDate) return undefined;

  const today = dayjs();
  let end: dayjs.Dayjs;

  switch (dueDate) {
    case 'next-week':
      end = today.add(1, 'week');
      break;
    case 'next-month':
      end = today.add(1, 'month');
      break;
    case 'next-three-months':
      end = today.add(3, 'months');
      break;
    default:
      return undefined;
  }

  return {
    start: today.format('YYYY-MM-DD'),
    end: end.format('YYYY-MM-DD'),
  };
}

function transformGestationalAge(
  ages: (keyof typeof GESTATIONAL_AGE_FILTERS)[]
): { min: number; max: number }[] | undefined {
  if (ages.length === 0) return undefined;

  return ages.map((age) => {
    switch (age) {
      case 'zero-to-twelve':
        return { min: 0, max: 12 };
      case 'thirteen-to-twentyseven':
        return { min: 13, max: 27 };
      case 'twentyeight-to-forty':
        return { min: 28, max: 40 };
      default:
        return { min: 41, max: 100 };
    }
  });
}
