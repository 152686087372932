import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router';

import ErrorBoundary from '../../../components/ErrorBoundary';
import CheckoutPage from '../../../pages/Checkout';
import ConfirmationPage from '../../../pages/Confirmation';
import Error from '../../../pages/Error';

const INGORED_RETURN_TO_URLS = [
  '/',
  '/signin',
  '/signup',
  '/checkout',
  '/confirmation',
];

export const StripeRouter = () => {
  const ErrorWrapper = () => (
    <ErrorBoundary fallback={<Error type={500} />}>
      <Outlet />
    </ErrorBoundary>
  );

  const NavigateWithReturnUrl = ({ to }: { to: string }) => {
    const location = useLocation();
    const params = new URLSearchParams();

    if (!INGORED_RETURN_TO_URLS.includes(location.pathname)) {
      params.set('return_to', location.pathname);
    }

    const search = params.toString();

    return <Navigate to={to + (search ? `?${search}` : '')} replace />;
  };

  return (
    <Routes>
      <Route element={<ErrorWrapper />}>
        <Route
          path="/checkout"
          element={<NavigateWithReturnUrl to="/checkout" />}
        />
        <Route
          path="/confirmation"
          element={<NavigateWithReturnUrl to="/confirmation" />}
        />
        <Route path="*" element={<NavigateWithReturnUrl to="/checkout" />} />
      </Route>
    </Routes>
  );
};
