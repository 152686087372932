import { z } from 'zod';
import { StaffListItemDTO } from '../staff';
import { TemplateType } from '../encounter';

export const InviteeDTO = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
});

export type InviteeDTO = z.infer<typeof InviteeDTO>;

export type AppointmentDTO = {
  practiceID: string;
  id: string;
  ownerID: string;
  encounterID?: string;
  startTime: string;
  endTime: string;
  note: string | null;
  telehealth: boolean;
  type: Type;
  ical?: Record<string, string>;
  timezone: string | null;
};

export type AppointmentWithInviteesDTO = AppointmentDTO & {
  invitedPatients: InviteeDTO[];
  invitedStaff: InviteeDTO[];
};

export type Type =
  | 'initial_visit'
  | 'new_ob'
  | 'return_ob'
  | 'return_gyn'
  | 'prob_gyn'
  | 'post_partum'
  | 'block'
  | 'prenatal'
  | '90min'
  | 'general'
  | 'lactation'
  | 'newborn_visit'
  | 'labs_only'
  | 'consultation'
  | 'home_visit'
  | 'class'
  | 'virtual';

export const AppointmentType = {
  initial_visit: 'initial_visit',
  new_ob: 'new_ob',
  return_ob: 'return_ob',
  return_gyn: 'return_gyn',
  prob_gyn: 'prob_gyn',
  post_partum: 'post_partum',
  block: 'block',
  prenatal: 'prenatal',
  '90min': '90min',
  general: 'general',
  lactation: 'lactation',
  newborn_visit: 'newborn_visit',
  labs_only: 'labs_only',
  consultation: 'consultation',
  home_visit: 'home_visit',
  class: 'class',
  virtual: 'virtual',
};

export const AppointmentTypeDisplayNames = {
  [AppointmentType.initial_visit]: 'Initial Visit',
  [AppointmentType.new_ob]: 'New OB',
  [AppointmentType.return_ob]: 'Return OB',
  [AppointmentType.return_gyn]: 'Return GYN',
  [AppointmentType.prob_gyn]: 'Prob GYN',
  [AppointmentType.post_partum]: 'Postpartum',
  [AppointmentType.block]: 'Block',
  [AppointmentType.prenatal]: 'Prenatal',
  [AppointmentType['90min']]: '90min',
  [AppointmentType.general]: 'General',
  [AppointmentType.lactation]: 'Lactation',
  [AppointmentType.newborn_visit]: 'Newborn Visit',
  [AppointmentType.labs_only]: 'Labs Only',
  [AppointmentType.consultation]: 'Consultation',
  [AppointmentType.home_visit]: 'Home Visit',
  [AppointmentType.class]: 'Class',
  [AppointmentType.virtual]: 'Virtual',
};

export const AppointmentTypeAltDisplayNames = {
  [AppointmentType.post_partum]: 'PP',
};

export type CreateAppointmentDTO = Omit<
  AppointmentDTO,
  'id' | 'practiceID' | 'ownerID' | 'timezone'
> & {
  invitedPatientIDs: string[];
  invitedStaffIDs: string[];
  encounterData?: {
    patientID: string;
    templateType: TemplateType;
  };
};

export type UpdateAppointmentDTO = CreateAppointmentDTO & { id: string };
