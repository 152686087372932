import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CircularProgress } from '@mui/material';
import Typography from '../../../components/Typography';
import {
  PregnancyInfoFields,
  usePregnancyInfoForm,
} from '../hooks/usePregnancyInfoForm';
import { FormControl } from '@aster/client/ui/FormControl/FormControl';
import { Label } from '@aster/client/ui/Label/Label';
import {
  RadioGroup,
  RadioIndicator,
  RadioItem,
} from '@aster/client/ui/Radio/Radio';
import { InputMask } from '@react-input/mask';
import { Input } from '@aster/client/ui/Input/Input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@aster/client/ui/Select/Select';
import { PatientInfoDTO } from '@aster/shared/dtos/patient';
import { Checkbox } from '@aster/client/ui/Checkbox/Checkbox';
import { isPatientReadonly } from '../utils/is-patient-readonly';
import { useDebouncedCallback } from 'use-debounce';
import { FormError } from '@aster/client/ui/FormControl/FormError';
import { useUserUsagePreferences } from '../../../../src/hooks/useUserUsagePreference';
import { LAST_SAVED_PREGNANCY_INFORMATION } from '../constants';
import { ProfileSaveButton } from '../profileTabs/components/ProfileSaveButton';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import timeSinceLastSaved from '../utils/timeSinceLastSaved';
import ConfirmationModal from '../../../components/ConfirmationModal';

const PregnancyInformation = forwardRef(
  (
    {
      updatePatientMutation,
      patientInfo,
      isPatientLoading,
    }: {
      patientInfo: PatientInfoDTO | undefined;
      updatePatientMutation: any;
      isPatientLoading: boolean;
    },
    ref
  ) => {
    const [debounceValue, setDebounceValue] = useState<number>(1000);
    useImperativeHandle(ref, () => ({
      submit: () => {
        setDebounceValue(0);
        pregnancyInfoForm.handleSubmit();
      },
    }));

    const [endPregnancyConfirmation, setEndPregnancyConfirmation] =
      useState(false);

    const { storePreference, readPreference } = useUserUsagePreferences();

    const lastSaved = readPreference<string>(LAST_SAVED_PREGNANCY_INFORMATION);
    const lastSavedString = lastSaved ? timeSinceLastSaved(lastSaved) : '';

    const pregnancyInfoForm = usePregnancyInfoForm({
      defaultValues: patientInfo as PatientInfoDTO,
      onSubmit: (value) => {
        const getPatientType = () => {
          if (
            patientInfo?.patientType === 'prenatal' &&
            !value.pregnant &&
            !value.skipMovingPregnancy
          ) {
            return 'postpartum';
          }
          if (
            !value.pregnant &&
            value.skipMovingPregnancy &&
            patientInfo?.patientType === 'prenatal'
          ) {
            return null;
          }
          if (
            value.pregnant &&
            (!patientInfo?.patientType ||
              patientInfo?.patientType === 'postpartum')
          ) {
            return 'prenatal';
          }
          return patientInfo?.patientType;
        };
        save({
          ...value,
          edd: value.edd ? dayjs(value.edd).toISOString() : undefined,
          lmp: value.lmp ? dayjs(value.lmp).toISOString() : undefined,
          dateOfConception: value.dateOfConception
            ? dayjs(value.dateOfConception).toISOString()
            : undefined,
          patientType: getPatientType(),
        })?.then(() => {
          storePreference(
            LAST_SAVED_PREGNANCY_INFORMATION,
            dayjs().toISOString()
          );
          setDebounceValue(1000);
        });
      },
    });

    const save = useDebouncedCallback(
      async (value: Partial<PregnancyInfoFields>) => {
        await updatePatientMutation.mutateAsync(value);
      },
      debounceValue
    );

    const readonly = isPatientReadonly(patientInfo);

    return (
      <>
        <ProfileSaveButton
          lastSavedString={lastSavedString}
          updatePatientMutation={updatePatientMutation}
          form={pregnancyInfoForm}
        />
        <div className="container w-full pb-20">
          {isPatientLoading ? (
            <CircularProgress />
          ) : (
            <form
              onSubmit={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
                pregnancyInfoForm.handleSubmit();
              }}
            >
              <div className="flex h-fit">
                <div className="flex flex-col gap-y-5 w-full sm:w-auto">
                  <Typography
                    text="Pregnancy Information"
                    variant="h5"
                    customClass="font-semibold"
                  ></Typography>
                  <pregnancyInfoForm.Field
                    name="pregnant"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Are you currently pregnant?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          defaultValue={
                            field.state.value === true
                              ? 'yes'
                              : field.state.value === false
                              ? 'no'
                              : undefined
                          }
                          value={field.state.value === true ? 'yes' : 'no'}
                          onValueChange={(value) => {
                            const isPregnant = value === 'yes';
                            if (!isPregnant) {
                              setEndPregnancyConfirmation(true);
                            } else {
                              field.handleChange(isPregnant);
                              pregnancyInfoForm.handleSubmit();
                            }
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-no`}
                              value="no"
                              checked={field.state.value === false}
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem
                              id={`${field.name}-yes`}
                              value="yes"
                              checked={field.state.value === true}
                            >
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {pregnancyInfoForm.state.values.pregnant && (
                    <div className="flex flex-col gap-y-5 w-full ">
                      <div className="flex flex-wrap gap-4 w-full ">
                        <pregnancyInfoForm.Field
                          name="lmp"
                          validators={{
                            onBlur: ({ value }: { value: string }) => {
                              if (!value) return null;
                              if (value.length > 1 && !dayjs(value).isValid()) {
                                return 'Invalid date';
                              }
                              return null;
                            },
                          }}
                          children={(field) => (
                            <FormControl className="w-full">
                              <Label>Last Menstrual Period (LMP)</Label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <InputMask
                                  component={Input}
                                  name={field.name}
                                  mask="MM/DD/YYYY"
                                  separate
                                  showMask
                                  replacement={{ D: /\d/, M: /\d/, Y: /\d/ }}
                                  value={field.state.value as string}
                                  onChange={(event) => {
                                    const value =
                                      event.target.value === 'MM/DD/YYYY'
                                        ? ''
                                        : event.target.value;
                                    field.handleChange(value ?? undefined);
                                  }}
                                  onBlur={(evt) => {
                                    field.handleBlur();
                                    if (
                                      (dayjs(evt.target.value).isValid() &&
                                        field.state.meta.isDirty) ||
                                      evt.target.value === ''
                                    ) {
                                      pregnancyInfoForm.handleSubmit();
                                    }
                                  }}
                                  disabled={readonly}
                                />
                              </LocalizationProvider>
                              <FormError id={`err-${field.name}`}>
                                {field.state.meta.errors?.join('\r')}
                              </FormError>
                            </FormControl>
                          )}
                        />
                        <pregnancyInfoForm.Field
                          name="edd"
                          validators={{
                            onBlur: ({ value }: { value: string }) => {
                              if (!value) return null;
                              if (value.length > 1 && !dayjs(value).isValid()) {
                                return 'Invalid date';
                              }
                              return null;
                            },
                          }}
                          children={(field) => (
                            <FormControl className="w-full">
                              <Label>Estimated Due Date (EDD)</Label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <InputMask
                                  component={Input}
                                  name={field.name}
                                  mask="MM/DD/YYYY"
                                  separate
                                  showMask
                                  replacement={{ D: /\d/, M: /\d/, Y: /\d/ }}
                                  value={field.state.value as string}
                                  onChange={(event) => {
                                    const value =
                                      event.target.value === 'MM/DD/YYYY'
                                        ? ''
                                        : event.target.value;
                                    field.handleChange(value ?? undefined);
                                  }}
                                  onBlur={(evt) => {
                                    field.handleBlur();
                                    if (
                                      (dayjs(evt.target.value).isValid() &&
                                        field.state.meta.isDirty) ||
                                      evt.target.value === ''
                                    ) {
                                      pregnancyInfoForm.handleSubmit();
                                    }
                                  }}
                                  disabled={readonly}
                                />
                              </LocalizationProvider>
                              <FormError id={`err-${field.name}`}>
                                {field.state.meta.errors?.join('\r')}
                              </FormError>
                            </FormControl>
                          )}
                        />
                      </div>
                      <div className="flex flex-wrap gap-4 w-full sm:w-auto">
                        <pregnancyInfoForm.Field
                          name="dateOfConception"
                          validators={{
                            onBlur: ({ value }: { value: string }) => {
                              if (!value) return null;
                              if (value.length > 1 && !dayjs(value).isValid()) {
                                return 'Invalid date';
                              }
                              return null;
                            },
                          }}
                          children={(field) => (
                            <FormControl className="w-full sm:w-auto">
                              <Label>Date of Conception</Label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <InputMask
                                  component={Input}
                                  name={field.name}
                                  mask="MM/DD/YYYY"
                                  separate
                                  showMask
                                  replacement={{ D: /\d/, M: /\d/, Y: /\d/ }}
                                  value={field.state.value as string}
                                  onChange={(event) => {
                                    const value =
                                      event.target.value === 'MM/DD/YYYY'
                                        ? ''
                                        : event.target.value;
                                    field.handleChange(value ?? undefined);
                                  }}
                                  onBlur={(evt) => {
                                    field.handleBlur();
                                    if (
                                      (dayjs(evt.target.value).isValid() &&
                                        field.state.meta.isDirty) ||
                                      evt.target.value === ''
                                    ) {
                                      pregnancyInfoForm.handleSubmit();
                                    }
                                  }}
                                  disabled={readonly}
                                />
                              </LocalizationProvider>
                              <FormError id={`err-${field.name}`}>
                                {field.state.meta.errors?.join('\r')}
                              </FormError>
                            </FormControl>
                          )}
                        />
                        <pregnancyInfoForm.Field
                          name="dateToCalculateDueDate"
                          children={(field) => (
                            <FormControl className="w-full sm:w-auto">
                              <Label>Date to calculate due date</Label>
                              <Select
                                value={field.state.value ?? ''}
                                onValueChange={(value) => {
                                  field.handleChange(
                                    value as PatientInfoDTO['dateToCalculateDueDate']
                                  );
                                  pregnancyInfoForm.handleSubmit();
                                }}
                                disabled={readonly}
                              >
                                <SelectTrigger className="bg-white">
                                  <SelectValue placeholder={'Select Date'} />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectGroup>
                                    <SelectItem value="edd">
                                      Estimated Due Date (EDD)
                                    </SelectItem>
                                    <SelectItem value="lmp">
                                      Last Menstrual Period (LMP)
                                    </SelectItem>
                                    <SelectItem value="dateOfConception">
                                      Date of conception
                                    </SelectItem>
                                  </SelectGroup>
                                </SelectContent>
                              </Select>
                            </FormControl>
                          )}
                        />
                      </div>
                      <pregnancyInfoForm.Field
                        name="unsureDueDate"
                        children={(field) => (
                          <FormControl className="flex flex-row items-center gap-2 relative">
                            <Checkbox
                              id={field.name}
                              checked={field.state.value as boolean}
                              onCheckedChange={(value) => {
                                field.handleChange(value as boolean);
                                pregnancyInfoForm.handleSubmit();
                              }}
                              disabled={readonly}
                            />
                            <Label
                              htmlFor={field.name}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Unsure due date?
                            </Label>
                          </FormControl>
                        )}
                      />
                    </div>
                  )}
                  <pregnancyInfoForm.Field
                    name="hadUltrasounds"
                    children={(field) => (
                      <FormControl>
                        <Label className="mb-2">
                          Have you had any ultrasound scans?
                        </Label>
                        <RadioGroup
                          className="gap-4"
                          defaultValue={
                            field.state.value === true
                              ? 'yes'
                              : field.state.value === false
                              ? 'no'
                              : undefined
                          }
                          onValueChange={(value) => {
                            const hasFamilyMedicalConditions = value === 'yes';
                            field.handleChange(hasFamilyMedicalConditions);
                            pregnancyInfoForm.handleSubmit();
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-no`} value="no">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-no`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              No
                            </Label>
                          </div>
                          <div className="flex gap-2 items-center">
                            <RadioItem id={`${field.name}-yes`} value="yes">
                              <RadioIndicator />
                            </RadioItem>
                            <Label
                              htmlFor={`${field.name}-yes`}
                              className="m-0 cursor-pointer text-aster-secondary font-normal"
                            >
                              Yes
                            </Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    )}
                  />
                  {pregnancyInfoForm.state.values.hadUltrasounds && (
                    <pregnancyInfoForm.Field
                      name="ultraSoundDetails"
                      children={(field) => (
                        <FormControl>
                          <Label>Ultrasound details</Label>
                          <Input
                            placeholder="Enter ultrasound details"
                            id={field.name}
                            name={field.name}
                            value={field.state.value as string}
                            onChange={(event) => {
                              field.handleChange(event.target.value);
                            }}
                            onBlur={(evt) => {
                              if (field.state.meta.isDirty) {
                                pregnancyInfoForm.handleSubmit();
                              }
                            }}
                            disabled={readonly}
                          />
                        </FormControl>
                      )}
                    />
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
        <ConfirmationModal
          open={endPregnancyConfirmation}
          title=""
          description={`You are ending the current pregnancy. Would you like to add this to the patient's obstetric history and automatically update their status to postpartum? Alternatively, you can just end the pregnancy without any further updates.`}
          confirm="Add to obstetric history"
          dismiss="Close"
          handleCancel={() => {
            setEndPregnancyConfirmation(false);
          }}
          handleClose={() => {
            setEndPregnancyConfirmation(false);
          }}
          handleConfirm={() => {
            setEndPregnancyConfirmation(false);
            pregnancyInfoForm.setFieldValue('skipMovingPregnancy', false);
            pregnancyInfoForm.setFieldValue('pregnant', false);
            pregnancyInfoForm.handleSubmit();
          }}
          secondaryConfirm="End pregnancy only"
          handleSecondaryConfirm={() => {
            setEndPregnancyConfirmation(false);
            pregnancyInfoForm.setFieldValue('skipMovingPregnancy', true);
            pregnancyInfoForm.setFieldValue('pregnant', false);
            pregnancyInfoForm.handleSubmit();
          }}
        />
      </>
    );
  }
);

export default PregnancyInformation;
