import { useState, useCallback, useMemo, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams } from 'react-router';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import ContractionCounter from './Birth/ContractionCounter';
import FHT from './Birth/FHT';
import Medications from './Birth/Medications';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ButtonType from '../../components/Button';
import Milestones from './Birth/Milestones';
import Notes from './Birth/Notes';
import CollapsibleComponent from '../../components/CollapsibleComponent';
import ConfirmationModal from '../../components/ConfirmationModal';
import Vitals from './sections/Vitals';
import Fluids from './Birth/Fluids';
import { colors } from '../../theme';
import { useLeavingPageProtection } from '../../hooks/usePageLeavingProtection';
import Typography from '../../components/Typography';
import dayjs from 'dayjs';
import { useAuth } from '../../authentication/AuthProvider';
import type { CreateEncounterDTO } from '@aster/shared/dtos/encounter';
import Signature from '../../assets/icons/Signature';
import BabyMilestones from './Birth/BabyMilestones';
import BabyVitals from './Birth/BabyVitals';
import Placenta from './Birth/Placenta';
import BloodLoss from './Birth/BloodLoss';
import Perineum from './Birth/Perineum';
import Uterus from './Birth/Uterus';
import Apgar from './Birth/Apgar';
import Resuscitation from './Birth/Resuscitation';
import BabyMeasurements from './Birth/BabyMeasurements';
import CodingModal from './components/CodingModal';
import { Codes } from './types/encounter';
import {
  BirthInfo,
  BloodLossInfo,
  FluidInfo,
  MedicationInfo,
  NoteInfo,
  ParentInfo,
  PerineumInfo,
  PlacentaInfo,
} from './Birth/types/birth.types';
import {
  BabyVitalsInfo,
  BabyMilestonesInfo,
  BabyMeasurementsInfo,
  ResuscitationInfo,
  BabyInfo,
} from './Birth/types/baby.types';
import { ApgarInfo } from './Birth/types/apgar.types';
import { useUpdateEncounterMutation } from './mutations/update-encounter.mutation';
import { useEncounterDetailQuery } from './queries/encounter-detail.query';
import { usePatientProfileQuery } from './queries/patient-profile.query';
import PatientProfileForEncounter from './sections/PatientProfileForEncounter';
import { useEnsureActivePregnancy } from './mutations/ensure-active-pregnancy.mutation';
import { downloadEncountersPdf } from './sections/utils';
import { usePatientVitals } from './sections/queries/fetch-vitals.query';
import Download from '../../assets/icons/Download';
import { isPatientReadonly } from '../patients/utils/is-patient-readonly';
import { names } from '@aster/shared/utils/names';
import { useUpdatePatientProfileMutation } from '../patients/mutations/update-patient-profile.mutation';
import { useQueryClient } from '@tanstack/react-query';

const BirthNote = () => {
  const { patient, encounterId } = useParams();
  const { encounterData } = useEncounterDetailQuery(encounterId as string);
  const { patientProfile } = usePatientProfileQuery(patient as string);

  const [ipp, setIpp] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [postpartumConfirmation, setPostpartumConfirmation] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [signed, setSigned] = useState(() => encounterData?.signedBy !== null);
  const [disableSave, setDisableSave] = useState(false);
  const [codeModalOpen, setCodeModalOpen] = useState(false);
  const [enforceSaveMissing, setEnforceSaveMissing] = useState(false);
  const [openRes, setOpenRes] = useState(false);
  const [selectedCodes, setSelectedCodes] = useState<Codes>({
    cptCodes: [],
    icdCodes: [],
  });
  const [birthState, setBirthState] = useState<BirthInfo>(() => ({
    milestones: encounterData?.content?.milestones || {
      arrival: null,
      rupture: null,
      onsetLabor: null,
      labor6: null,
      labor10: null,
      pushing: null,
      headOut: null,
      babyBorn: null,
      firstBreath: null,
      presentingPart: null,
      umbilicus: null,
    },
    notes: encounterData?.content?.notes || [],
    vitals: encounterData?.content?.vitals || [],
    fluids: encounterData?.content?.fluids || [],
    fht: encounterData?.content?.fht || [],
    contractions: encounterData?.content?.contractions || [],
    medications: encounterData?.content?.medications || [],
  }));
  const [parentState, setParentState] = useState<ParentInfo>(() => ({
    placenta: encounterData?.content?.placenta || {
      placentaOut: null,
      cordCut: null,
    },
    bloodLoss: encounterData?.content?.bloodLoss || [],
    perineum: encounterData?.content?.perineum || {},
    uterus: encounterData?.content?.uterus || {},
    timeOfDeparture: encounterData?.content?.timeOfDeparture || null,
    parentNotes: encounterData?.content?.parentNotes || [],
  }));
  const [babyState, setBabyState] = useState<BabyInfo>(() => ({
    babyMilestones: encounterData?.content?.babyMilestones || {
      firstCry: null,
      skinToSkin: null,
      firstLatch: null,
      feedingStart: null,
    },
    babyVitals: encounterData?.content?.babyVitals || [],
    apgar: encounterData?.content?.apgar || [],
    babyMeasurements: encounterData?.content?.babyMeasurements || {
      length: '',
      weight: '',
      hc: '',
      cc: '',
    },
    medications: encounterData?.content?.babyMedications || [],
    babyNotes: encounterData?.content?.babyNotes || [],
    resuscitation: encounterData?.content?.resuscitation || {
      dry: null,
      stimulate: null,
      suction: null,
      oxigen: null,
      gel: null,
      epi: null,
      ppv: null,
      chest: null,
      other: null,
      emsCall: null,
      emsArrival: null,
      emsDeparture: null,
      resNote: '',
      additionalNote: '',
      emsNote: '',
      apgar: [],
      babyVitals: encounterData?.content?.babyVitals || [],
    },
  }));
  const [openCreatePregnancyModal, setOpenCreatePregnancyModal] =
    useState(false);

  const { updatePatientProfileMutation } = useUpdatePatientProfileMutation(
    patient as string,
    {
      onSuccess: () => {
        setIpp(true);
      },
      onMutate: () => {
        setPostpartumConfirmation(false);
      },
    }
  );

  useEffect(() => {
    if (
      patientProfile &&
      !patientProfile?.isPregnant &&
      !encounterData?.signed
    ) {
      setOpenCreatePregnancyModal(true);
    }
  }, [encounterData?.signed, patientProfile]);

  const { vitals } = usePatientVitals(patient as string);

  const { ensurePatientActivePregnancy } = useEnsureActivePregnancy(() => {
    setOpenCreatePregnancyModal(false);
  });

  const { profile } = useAuth();
  const { blocker } = useLeavingPageProtection({ canLeave: !isDirty });

  const { updateEncounter } = useUpdateEncounterMutation(encounterId, () => {
    setIsDirty(false);
    setEnforceSaveMissing(false);
    setDisableSave(false);
  });

  const saveInfo = useCallback(
    (signed: boolean) => {
      setSigned(signed);
      setDisableSave(true);
      const info: CreateEncounterDTO = {
        patientID: patient as string,
        templateType: 'birthflow',
        signed,
        content: {
          milestones: birthState.milestones,
          notes: birthState.notes,
          vitals: birthState.vitals,
          fluids: birthState.fluids,
          fht: birthState.fht,
          contractions: birthState.contractions,
          medications: birthState.medications,
          babyMilestones: babyState.babyMilestones,
          babyVitals: babyState.babyVitals,
          apgar: babyState.apgar,
          babyMedications: babyState.medications,
          babyMeasurements: babyState.babyMeasurements,
          babyNotes: babyState.babyNotes,
          placenta: parentState.placenta,
          bloodLoss: parentState.bloodLoss,
          perineum: parentState.perineum,
          uterus: parentState.uterus,
          timeOfDeparture: parentState.timeOfDeparture,
          parentNotes: parentState.parentNotes,
          resuscitation: babyState.resuscitation,
        },
      };
      if (signed) {
        setOpenConfirmation(false);
      }
      updateEncounter.mutate(info);
    },
    [
      babyState.apgar,
      babyState.babyMeasurements,
      babyState.babyMilestones,
      babyState.babyNotes,
      babyState.babyVitals,
      babyState.medications,
      babyState.resuscitation,
      birthState.contractions,
      birthState.fht,
      birthState.fluids,
      birthState.medications,
      birthState.milestones,
      birthState.notes,
      birthState.vitals,
      parentState.bloodLoss,
      parentState.parentNotes,
      parentState.perineum,
      parentState.placenta,
      parentState.timeOfDeparture,
      parentState.uterus,
      patient,
      updateEncounter,
    ]
  );

  const handleClose = useCallback(
    (confirmed: boolean) => {
      if (confirmed) {
        setEnforceSaveMissing(true);
        saveInfo(false);
        blocker?.reset?.();
      } else {
        blocker?.proceed?.();
        setIsDirty(false);
        setEnforceSaveMissing(false);
      }
    },
    [blocker, saveInfo]
  );

  const timestampAuthor = useMemo(() => {
    const currentDateTime = new Date();
    let displayInfo = currentDateTime.toLocaleString();
    if (profile)
      displayInfo = `${currentDateTime.toLocaleString()}, ${
        profile.firstName
      } ${profile.lastName} `;
    return displayInfo;
  }, [profile]);

  const handleSave = useCallback(
    (property: string, data: PlacentaInfo | FluidInfo[] | NoteInfo[]) => {
      setBirthState((prevState) => ({ ...prevState, [property]: data }));
      setIsDirty(true);
    },
    []
  );

  const handleSaveParent = (
    property: string,
    data: PlacentaInfo | BloodLossInfo[] | NoteInfo[] | PerineumInfo
  ) => {
    setParentState((prevState) => ({ ...prevState, [property]: data }));
    setIsDirty(true);
  };
  const handleSaveBaby = (
    property: string,
    data:
      | Partial<BabyVitalsInfo>[]
      | BabyMilestonesInfo
      | Partial<ApgarInfo>[]
      | BabyMeasurementsInfo
      | ResuscitationInfo
      | MedicationInfo[]
      | NoteInfo[]
  ) => {
    setBabyState((prevState) => ({ ...prevState, [property]: data }));
    setIsDirty(true);
  };

  const handleNote = useCallback(
    (note: string) => {
      const newNote = {
        content: note,
        timestamp: timestampAuthor,
      };

      if (ipp) {
        setParentState((prevState) => ({
          ...prevState,
          parentNotes: [newNote, ...prevState.parentNotes],
        }));
      } else {
        setBirthState((prevState) => ({
          ...prevState,
          notes: [newNote, ...prevState.notes],
        }));
      }
      setIsDirty(true);
    },
    [ipp, timestampAuthor]
  );

  const handleParentNote = useCallback(
    (note: string) => {
      const newNote = {
        content: note,
        timestamp: timestampAuthor,
      };

      setParentState((prevState) => ({
        ...prevState,
        parentNotes: [newNote, ...prevState.parentNotes],
      }));
      setIsDirty(true);
    },
    [timestampAuthor]
  );

  const handleBabyNote = useCallback(
    (note: string) => {
      const newNote = {
        content: note,
        timestamp: timestampAuthor,
      };

      setBabyState((prevState) => ({
        ...prevState,
        babyNotes: [newNote, ...prevState.babyNotes],
      }));
      setIsDirty(true);
    },
    [timestampAuthor]
  );

  const handleUnsaved = useCallback((isDirty: boolean) => {
    setIsDirty(isDirty);
  }, []);

  //Immediate Postpartum
  if (ipp) {
    return (
      <div className="flex flex-col lg:flex-row overflow-auto">
        {/* Left Part */}
        <div className="w-full lg:w-[400px]">
          <PatientProfileForEncounter
            className="md:w-full lg:w-[400px]"
            isEncounterSigned={signed}
            enforceSaveMissing={enforceSaveMissing}
            handleUnsaved={handleUnsaved}
            handleNote={handleNote}
          />
        </div>
        {/* Right part */}
        <div className="flex flex-col flex-1 h-full ml-1 lg:overflow-y-auto gap-2 pb-8 md:pb-0">
          {/* Header */}
          <div className="flex flex-row flex-wrap justify-between items-center px-5 mt-4 w-full gap-2 py-4 lg:py-0">
            <div className="flex flow-row">
              <Typography variant="h1" text="Immediate Postpartum" />
            </div>

            <div className="flex flex-col md:flex-row gap-3">
              {patientProfile && vitals && encounterData && (
                <ButtonType
                  variant="outlined"
                  text="Download Encounter"
                  onClick={() => {
                    downloadEncountersPdf({
                      encounters: [encounterData],
                      patientProfile: patientProfile,
                      vitals: vitals,
                    });
                  }}
                  icon={true}
                  specificIcon={<Download className="mr-2" />}
                />
              )}
              <ButtonType
                variant="outlined"
                text="Code"
                disabled={updateEncounter.status === 'pending'}
                onClick={() => setCodeModalOpen(true)}
                icon
                specificIcon={
                  <RequestQuoteOutlinedIcon sx={{ marginRight: '5px' }} />
                }
              />
              {!signed && !isPatientReadonly(patientProfile) && (
                <ButtonType
                  variant="outlined"
                  text="Save Draft"
                  disabled={updateEncounter.status === 'pending'}
                  loading={disableSave}
                  onClick={() => saveInfo(false)}
                />
              )}

              {!signed && !isPatientReadonly(patientProfile) && (
                <ButtonType
                  variant="contained"
                  text="Sign"
                  onClick={() => setOpenConfirmation(true)}
                  disabled={updateEncounter.status === 'pending'}
                  classes={'max-w-[100px]'}
                  icon
                  specificIcon={
                    <span className="px-1 pb-1">
                      <Signature />
                    </span>
                  }
                />
              )}
              <ButtonType
                variant="contained"
                text="Labor & Birth"
                onClick={() => {
                  setIpp(false);
                }}
                icon
                specificIcon={<DoubleArrowIcon className="mr-1 rotate-180" />}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row mt-2 lg:overflow-hidden w-full px-1 gap-y-8 gap-2 pb-8">
            {/* Mom's part*/}
            <div className="flex flex-col w-full md:w-1/2 h-full pl-1">
              <div className="lg:h-[60%] lg:min-h-[60%] lg:overflow-auto">
                <div>
                  <Typography
                    text="Parent's Information"
                    variant="h5"
                    customClass="mb-4 pl-2"
                  />
                </div>

                <Placenta
                  data={parentState.placenta}
                  disabled={signed || isPatientReadonly(patientProfile)}
                  handleNote={handleParentNote}
                  handleSave={handleSaveParent}
                />
                <div>
                  <BloodLoss
                    disabled={signed || isPatientReadonly(patientProfile)}
                    data={parentState.bloodLoss}
                    handleNote={handleParentNote}
                    handleSave={handleSaveParent}
                  />
                </div>
                <div>
                  <Perineum
                    disabled={signed || isPatientReadonly(patientProfile)}
                    data={parentState.perineum}
                    handleNote={handleParentNote}
                    handleSave={handleSaveParent}
                  />
                </div>
                <div>
                  <Uterus
                    disabled={signed || isPatientReadonly(patientProfile)}
                    data={parentState.uterus}
                    handleNote={handleParentNote}
                    handleSave={handleSaveParent}
                  />
                </div>
              </div>

              <div className="hidden relative lg:flex flex-col justify-center items-center my-1.5">
                <hr className="absolute w-full border-t-2 border-grayBackground top-1/2 transform-translate-y-1/2" />
                <button className="bg-grayBackground text-gray-500 rounded-full p-0.5 hover:bg-gray-700 z-20">
                  <ExpandMoreIcon />
                </button>
              </div>
              <div className="overflow-y-auto ">
                <Notes
                  type="IP Parent"
                  data={parentState.parentNotes}
                  patientData={patientProfile}
                  disabled={signed || isPatientReadonly(patientProfile)}
                  nameNote="parentNotes"
                  patient={encounterData?.patientName || ''}
                  handleSave={handleSaveParent}
                  handleUnsaved={handleUnsaved}
                  enforceSaveMissing={enforceSaveMissing}
                  useLargeNotes={false}
                />
              </div>
            </div>

            {/* Baby's part */}
            <div className="flex flex-col flex-1 w-full max-w-full md:w-1/2 h-full">
              <div className="lg:h-[60%] lg:min-h-[60%] overflow-auto">
                <div>
                  <div className="flex flex-row flex-wrap justify-between lg:items-center w-full mb-2">
                    <Typography variant="h5" text="Baby's Information" />
                    <ButtonType
                      variant="text"
                      text="Resuscitation"
                      classes="bg-red-500 text-white h-8  hover:text-black"
                      onClick={() => setOpenRes(true)}
                    />
                  </div>
                </div>
                <BabyVitals
                  data={babyState.babyVitals}
                  disabled={signed || isPatientReadonly(patientProfile)}
                  handleSave={handleSaveBaby}
                  handleNote={handleBabyNote}
                />

                <div className="my-4">
                  <BabyMilestones
                    disabled={signed || isPatientReadonly(patientProfile)}
                    data={babyState.babyMilestones}
                    handleSave={handleSaveBaby}
                    handleNote={handleBabyNote}
                  />
                </div>

                <div className="my-4">
                  <Apgar
                    data={babyState.apgar}
                    disabled={signed || isPatientReadonly(patientProfile)}
                    handleSave={handleSaveBaby}
                    handleNote={handleBabyNote}
                  />
                </div>
                <div className="my-4">
                  <BabyMeasurements
                    data={babyState.babyMeasurements}
                    disabled={signed || isPatientReadonly(patientProfile)}
                    handleSave={handleSaveBaby}
                    handleNote={handleBabyNote}
                  />
                </div>
                <div className="my-4 w-full">
                  <Medications
                    data={babyState.medications}
                    disabled={signed || isPatientReadonly(patientProfile)}
                    color="bg-beige"
                    handleSave={handleSaveBaby}
                    handleNote={handleBabyNote}
                  />
                </div>
              </div>

              <div className="hidden relative lg:flex flex-col justify-center items-center my-2">
                <hr className="absolute w-full border-t-2 border-grayBackground top-1/2 transform -translate-y-1/2" />
                <button className="bg-grayBackground text-gray-500 rounded-full hover:bg-gray-700 z-20">
                  <ExpandMoreIcon />
                </button>
              </div>
              <div className="overflow-y-auto">
                <Notes
                  type="IP Baby"
                  data={babyState.babyNotes}
                  patientData={patientProfile}
                  nameNote="babyNotes"
                  disabled={signed || isPatientReadonly(patientProfile)}
                  patient={encounterData?.patientName || ''}
                  handleSave={handleSaveBaby}
                  enforceSaveMissing={enforceSaveMissing}
                  handleUnsaved={handleUnsaved}
                  useLargeNotes={false}
                />
              </div>
            </div>
          </div>
        </div>
        <ConfirmationModal
          open={blocker.state === 'blocked'}
          title="Unsaved Changes"
          description={`Are you sure you want to leave? Changes may not be saved.`}
          confirm="Save"
          dismiss="Leave"
          handleClose={() => handleClose(true)}
          handleConfirm={() => handleClose(true)}
          handleCancel={() => handleClose(false)}
        />
        <ConfirmationModal
          open={openConfirmation}
          title="Sign this encounter note?"
          description={`You're signing as ${profile && profile?.firstName} ${
            profile && profile?.lastName
          }`}
          confirm="Sign"
          dismiss="Cancel"
          handleClose={() => setOpenConfirmation(false)}
          handleConfirm={() => saveInfo(true)}
          handleCancel={() => setOpenConfirmation(false)}
        />
        <Resuscitation
          open={openRes}
          disabled={signed || isPatientReadonly(patientProfile)}
          babyVitals={babyState.babyVitals}
          apgar={babyState.apgar}
          data={babyState.resuscitation}
          setClose={() => setOpenRes(false)}
          handleNote={handleBabyNote}
          handleSave={handleSaveBaby}
        />
        <CodingModal
          readonly={signed || isPatientReadonly(patientProfile)}
          encounterInfo={encounterData}
          selectedCodes={selectedCodes}
          setSelectedCodes={setSelectedCodes}
          open={codeModalOpen}
          handleClose={() => setCodeModalOpen(false)}
          dismiss="Cancel"
          confirm="Save"
        />
      </div>
    );
  }

  //Labor
  return (
    <div className="flex flex-col flex-1 lg:flex-row overflow-auto">
      {/* Left Part */}
      <div className="flex flex-col lg:max-w-[400px]">
        {patientProfile && (
          <PatientProfileForEncounter
            className="md:w-full lg:w-[400px]"
            isEncounterSigned={signed}
            enforceSaveMissing={enforceSaveMissing}
            handleUnsaved={handleUnsaved}
          >
            <Vitals
              enforceSaveMissing={enforceSaveMissing}
              handleNote={handleNote}
              disabled={signed || isPatientReadonly(patientProfile)}
              handleUnsaved={handleUnsaved}
            />
            <Fluids
              data={birthState.fluids}
              disabled={signed || isPatientReadonly(patientProfile)}
              handleSave={handleSave}
              handleNote={handleNote}
            />
            <FHT
              data={birthState.fht}
              disabled={signed || isPatientReadonly(patientProfile)}
              handleSave={handleSave}
              handleNote={handleNote}
            />
            <ContractionCounter
              data={birthState.contractions}
              disabled={signed || isPatientReadonly(patientProfile)}
              handleSave={handleSave}
              handleNote={handleNote}
            />
            <Medications
              data={birthState.medications}
              disabled={signed || isPatientReadonly(patientProfile)}
              handleSave={handleSave}
              handleNote={handleNote}
            />
          </PatientProfileForEncounter>
        )}
      </div>
      {/* Right part */}
      <div className="flex flex-col mt-5 px-4 flex-1 pb-8 lg:overflow-y-auto">
        <div className="flex flex-col gap-[15px] mt-5">
          <div className="flex flex-row flex-wrap justify-between items-center mx-4 md:px-10">
            <div className="flex flex-col w-40%">
              <Typography
                variant="h1"
                customClass={'leading-none'}
                text="Labor & birth"
              />
            </div>
            <div className="flex">
              <div className="flex flex-col md:flex-row md:items-center md:gap-5">
                <Typography
                  variant="body"
                  text="Started on: "
                  customClass="mt-2 text-gray-500"
                />
                <div className="flex items-center flex-row gap-5">
                  <EventIcon
                    sx={{ color: colors.gray, marginTop: 0.8, marginRight: -2 }}
                  />
                  <Typography
                    variant="body"
                    text={
                      encounterData?.startTime
                        ? dayjs(encounterData.startTime).format('ddd MMM D')
                        : ''
                    }
                    customClass="mt-2 text-gray-500"
                  />
                </div>

                <div className="flex items-center flex-row gap-5">
                  <AccessTimeIcon
                    sx={{ color: colors.gray, marginTop: 0.8, marginRight: -2 }}
                  />
                  <Typography
                    variant="body"
                    text={
                      encounterData?.startTime
                        ? dayjs(encounterData.startTime).format('HH:mm:ss')
                        : ''
                    }
                    customClass="mt-2 text-gray-500"
                  />
                </div>
                {patientProfile && vitals && encounterData && (
                  <ButtonType
                    variant="outlined"
                    text="Download Encounter"
                    onClick={() => {
                      downloadEncountersPdf({
                        encounters: [encounterData],
                        patientProfile: patientProfile,
                        vitals: vitals,
                      });
                    }}
                    icon={true}
                    specificIcon={<Download className="mr-2" />}
                  />
                )}
                <ButtonType
                  variant="outlined"
                  text="Code"
                  onClick={() => setCodeModalOpen(true)}
                  disabled={updateEncounter.status === 'pending'}
                  icon
                  specificIcon={
                    <RequestQuoteOutlinedIcon sx={{ marginRight: '5px' }} />
                  }
                />
                {!signed && !isPatientReadonly(patientProfile) && (
                  <ButtonType
                    variant="outlined"
                    text="Save Draft"
                    disabled={
                      updateEncounter.status === 'pending' ||
                      isPatientReadonly(patientProfile)
                    }
                    loading={disableSave}
                    onClick={() => saveInfo(false)}
                  />
                )}
                <ButtonType
                  variant="contained"
                  text="Postpartum"
                  onClick={() => {
                    if (patientProfile?.patientType === 'prenatal') {
                      setPostpartumConfirmation(true);
                    } else {
                      setIpp(true);
                    }
                  }}
                  icon
                  specificIcon={<DoubleArrowIcon className="mr-1" />}
                />
              </div>
            </div>
          </div>

          <div className="flex w-full h-full flex-col xs:ml-10">
            <div className="flex flex-col flex-grow lg:px-5">
              <div className="w-full overflow-hidden">
                <CollapsibleComponent label="Milestones">
                  <Milestones
                    disabled={signed || isPatientReadonly(patientProfile)}
                    data={birthState.milestones}
                    handleSave={handleSave}
                    handleNote={handleNote}
                  />
                </CollapsibleComponent>
              </div>
              <div className="mt-5">
                <Notes
                  type="Birth"
                  data={birthState.notes}
                  patientData={patientProfile}
                  nameNote="notes"
                  disabled={signed || isPatientReadonly(patientProfile)}
                  patient={encounterData?.patientName || ''}
                  handleSave={handleSave}
                  handleUnsaved={handleUnsaved}
                  enforceSaveMissing={enforceSaveMissing}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        open={blocker.state === 'blocked'}
        title="Unsaved Changes"
        description={`Are you sure you want to leave? Changes may not be saved.`}
        confirm="Save"
        dismiss="Leave"
        handleClose={() => handleClose(true)}
        handleConfirm={() => handleClose(true)}
        handleCancel={() => handleClose(false)}
      />
      <CodingModal
        readonly={signed || isPatientReadonly(patientProfile)}
        encounterInfo={encounterData}
        selectedCodes={selectedCodes}
        setSelectedCodes={setSelectedCodes}
        open={codeModalOpen}
        handleClose={() => setCodeModalOpen(false)}
        dismiss="Cancel"
        confirm="Save"
      />
      <ConfirmationModal
        open={openCreatePregnancyModal}
        title="Active pregnancy"
        description="This patient does not have an active pregnancy. Do you want to create one?"
        confirm="Yes"
        dismiss="No, I'm just looking"
        loading={ensurePatientActivePregnancy.status === 'pending'}
        handleClose={() => setOpenCreatePregnancyModal(false)}
        handleConfirm={() =>
          ensurePatientActivePregnancy.mutate(patient as string)
        }
        handleCancel={() => setOpenCreatePregnancyModal(false)}
      />
      {patientProfile?.patientType === 'prenatal' && (
        <ConfirmationModal
          open={postpartumConfirmation}
          title=""
          description={`Update the patient type to Postpartum? Patient type is currently set to ${
            names(patientProfile?.patientType).sentenceCase
          }.`}
          confirm="Update"
          dismiss="Close"
          handleClose={() => setPostpartumConfirmation(false)}
          handleConfirm={() => {
            updatePatientProfileMutation.mutate({
              ...patientProfile,
              patientType: 'postpartum',
            });
          }}
          secondaryConfirm="Continue without updating"
          handleSecondaryConfirm={() => {
            setIpp(true);
            setPostpartumConfirmation(false);
          }}
          handleCancel={() => {
            setPostpartumConfirmation(false);
          }}
        />
      )}
    </div>
  );
};

export default BirthNote;
